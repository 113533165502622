<template>
  <div>
    <v-row dense class="my-0">
      <v-col>
        <v-row dense>
          <v-col cols="1">
            <v-select outlined required v-model="newMatMed.aditivo_matmed" item-value="value" item-text="nome"
              :items="statusOptions" :rules="requiredField">
              <template v-slot:label>
                <span>Aditivo<span style="color: red;">*</span></span>
              </template>
            </v-select>
          </v-col>
          <v-col v-if="newMatMed.aditivo_matmed" cols="1">
            <v-text-field outlined required v-model="newMatMed.nr_aditivo" type="number" :rules="nrAditivoRules(newMatMed)">
              <template v-slot:label>
                <span>Nrº do Aditivo <span style="color: red;">*</span></span>
              </template>
            </v-text-field>
          </v-col>
          <v-col v-if="newMatMed.aditivo_matmed" cols="3">
            <v-text-field outlined required v-model="newMatMed.data_aditivo" type="date" :rules="dataAditivoRules()">
              <template v-slot:label>
                <span>Data Início do Aditivo <span style="color: red;">*</span></span>
              </template>
            </v-text-field>
          </v-col>
          <v-col cols="5">
            <v-autocomplete outlined required v-model="newMatMed.matmed" :items="formattedmatmed" item-value="id" item-text="displayText"
              :rules="requiredField" :loading="loadingmatmed">
              <template v-slot:label>
                <span>Taxa <span style="color: red;">*</span></span>
              </template> 
            </v-autocomplete>
          </v-col>
          <v-col cols="2">
            <vuetify-money outlined label=Preço required v-model="newMatMed.preco" type="number" :rules="requiredField"
              :options="options">
            </vuetify-money>
          </v-col>
          <v-col cols="3">
            <v-text-field outlined required v-model="newMatMed.data_inicio_matmed" type="date" :rules="requiredField">
              <template v-slot:label>
                <span>Data Início da Taxa <span style="color: red;">*</span></span>
              </template>
            </v-text-field>
          </v-col>
          <v-col cols="3">
            <v-text-field outlined required v-model="newMatMed.data_fim_matmed" type="date" :rules="requiredField">
              <template v-slot:label>
                <span>Data Fim da Taxa<span style="color: red;">*</span></span>
              </template>
            </v-text-field>
          </v-col>
          <v-col cols="2">
            <v-text-field outlined required v-model="newMatMed.quantidade_matmed" type="number" :rules="requiredField">
              <template v-slot:label>
                <span>Quantidade <span style="color: red;">*</span></span>
              </template>
            </v-text-field>
          </v-col>
          <v-col cols="2">
            <vuetify-money outlined label="Valor Total" required v-model="newMatMed.valor_cobranca_final_matmed"
              type="number" :rules="requiredField" :readonly="true">
            </vuetify-money>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row class="justify-end" dense>
      <ConfirmButton color="success" :loading="loading" :onConfirm="onBeforeSubmit" :block="false">
        Salvar
      </ConfirmButton>
    </v-row>
  </div>
</template>

<script>
import UtilsFunc from '../../../service/utilsFunc';
import ConfirmButton from '../../ConfirmButton.vue';
import api from "../../../http";

export default {
  name: "GenerateProceduresFormTaxa",
  props: {
    operadoraId: Number,
    modalidade: Object,
    orcamentoId: Number,
    statusOptions: Array,
    loading: Boolean,
    onSubmit: Function,
    dataInicio: [Date, String],
    dataFim: [Date, String],
  },
  data: () => UtilsFunc.withCRUDUtils({
    validating: false,
    matmed: [],
    loadingmatmed: false,
    aditivo: [],
    maxNrAditivo: null,
    maxDataAditivo: null,
    pacoteId: [],
    newMatMed: {
      taxa: null,
      preco: '',
      data_inicio_matmed: '',
      data_fim_matmed: '',
      quantidade_matmed: '',
      valor_cobranca_final_matmed: '',
      aditivo_matmed: false,
      nr_aditivo: null,
      data_aditivo: null,
      operadora: null,
      taxa_ref: null,
    },
    isLoading: true,
    nrAditivoRules(newMatMed) {
      if (newMatMed.aditivo === true) {
        return [v => !!v || 'Nrº Aditivo é obrigatório'];
      }
      return [];
    },
    options: {
      locale: "pt-BR",
      prefix: "R$",
      suffix: "",
      length: 11,
      precision: 2
    },
    currencyMask: {
      decimal: ',',
      thousands: '.',
      precision: 2,
    },
    requiredField: [e => (e !== null && e !== undefined && e !== '') || 'Obrigatório'],
  }),
  methods: {
    async getMatmed() {
      this.loadingmatmed = true
      // const operadoraId = this.operadoraId;
      try {
        const { data } = await api.get(`/matmed/matmeds/`);
        this.matmed = data
          .filter(taxa => taxa.ativo === true)
          .sort((a, b) => a.nome_substancia.localeCompare(b.nome_substancia));
        // console.log(this.matmed)
      } catch (error) {
        this.$toast.error(`Desculpe, Aconteceu algum erro. ${error}`);
      } finally {
        this.loadingmatmed = false;
      }
    },
    async getAditivo() {
      this.loadingmatmed = true
      const orcamentoId = this.orcamentoId;
      try {
        const { data } = await api.get(`atendimentos/aditivo/?sessao=${orcamentoId}`);
        this.aditivo = data;
        this.maxNrAditivo = data.nr_aditivo;
        this.maxDataAditivo = this.formatDate(data.data_aditivo);
        // console.log(this.aditivo); // Debug
      } catch (error) {
        this.$toast.error(`Desculpe, Aconteceu algum erro com a parte do Aditivo. ${error}`);
      } finally {
        this.loadingmatmed = false;
      }
    },
    async getPacote() {
      this.loadingmatmed = true
      const operadora = this.operadoraId
      const modalidade = this.modalidade.id
      try {
        const { data } = await api.get(`operadoras/operadora-pacotes/?operadora=${operadora}`);
        const pacote = data.find(item => item.ativo && item.modalidade.id === modalidade);
        this.pacoteId = pacote ? pacote.id : null;
      } catch (error) {
        this.$toast.error(`Desculpe, Aconteceu algum erro com a parte do Aditivo. ${error}`);
      } finally {
        this.loadingmatmed = false;
      }
    },
    async onBeforeSubmit() {
      this.validating = true;
      let isValid = true;
      // Verificar se data_inicio_matmed não é menor que dataInicio
      const dataIniciomatmed = new Date(this.newMatMed.data_inicio_matmed);
      const dataInicio = new Date(this.dataInicio);
      const dataFim = new Date(this.dataFim);

      if (dataIniciomatmed < dataInicio) {
        this.newMatMed.data_inicio_matmed = this.formatDate(this.dataInicio);
      }

      if (dataIniciomatmed > dataFim) {
        isValid = false;
        this.$toast.error('Data de Início não pode ser maior que a Data de Fim.');
      }

      if (this.newMatMed.aditivo_matmed && !this.newMatMed.nr_aditivo) {
        isValid = false;
        this.$toast.error('Por favor, preencha o Nrº do Aditivo para todos os campos onde Aditivo está como Sim.');
      }

      if (this.newMatMed.aditivo_matmed) {
        const dataAditivo = new Date(this.newMatMed.data_aditivo);
        if (dataAditivo < dataInicio || dataAditivo > dataFim) {
          isValid = false;
          this.$toast.error('Data Início do Aditivo deve estar entre a Data de Início e a Data de Fim do Orçamento.');
        }
      }

      if (!this.allFieldsValid()) {
        this.$toast.error('Por favor, preencha todos os campos antes de continuar!');
        this.validating = false;
        return;
      }

      if (!isValid) {
        this.validating = false;
        return;
      }
      const fields = {

        sessao: this.orcamentoId,
        matmed: this.newMatMed.matmed,
        data_inicio_matmed: this.newMatMed.data_inicio_matmed,
        data_fim_matmed: this.newMatMed.data_fim_matmed,
        quantidade_matmed: this.newMatMed.quantidade_matmed,
        valor_cobranca_matmed: this.newMatMed.preco,
        valor_cobranca_final_matmed: this.newMatMed.valor_cobranca_final_matmed,
        aditivo_matmed: this.newMatMed.aditivo_matmed,
        pacote: this.pacoteId,
        operadora: this.operadoraId,
      };
      // console.log('Gerando matmed --> ', fields)
      if (this.newMatMed.aditivo_matmed) {
        fields.nr_aditivo = this.newMatMed.nr_aditivo;
        fields.data_aditivo = this.newMatMed.data_aditivo;
      }
      await this.onSubmit(fields);
      this.validating = false;
    },
    allFieldsValid() {
      return this.newMatMed.matmed && this.newMatMed.preco && this.newMatMed.data_inicio_matmed && this.newMatMed.data_fim_matmed && this.newMatMed.quantidade_matmed && this.newMatMed.valor_cobranca_final_matmed !== '';
    },
    calculateDaysBetweenDates(startDate, endDate) {
      const start = new Date(startDate);
      const end = new Date(endDate);
      const timeDiff = Math.abs(end - start);
      return Math.ceil(timeDiff / (1000 * 3600 * 24)) + 1;
    },
    updateQuantityAndTotal() {
      if (this.newMatMed.data_inicio_matmed && this.newMatMed.data_fim_matmed) {
        const days = this.calculateDaysBetweenDates(this.newMatMed.data_inicio_matmed, this.newMatMed.data_fim_matmed);
        this.newMatMed.quantidade_matmed = days;
        this.newMatMed.valor_cobranca_final_matmed = (this.newMatMed.preco * days).toFixed(2);
      }
    },
    dataAditivoRules() {
      return [
        v => !!v || 'Data Início do Aditivo é obrigatória',
        v => new Date(v) <= new Date(this.dataFim) || 'Data Início do Aditivo não pode ser maior que a Data de Fim do Orçamento.',
      ];
    },
    updateTotal() {
      const preco = parseFloat(this.newMatMed.preco) || 0;
      const quantidade = parseInt(this.newMatMed.quantidade_matmed) || 0;
      this.newMatMed.valor_cobranca_final_matmed = (preco * quantidade).toFixed(2);
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      date.setDate(date.getDate() + 1);
      const year = date.getFullYear();
      const month = (`0${date.getMonth() + 1}`).slice(-2);
      const day = (`0${date.getDate()}`).slice(-2);
      return `${year}-${month}-${day}`;
    },
    formatDateHoje(dateString) {
      const date = new Date(dateString);
      date.setDate(date.getDate());
      const year = date.getFullYear();
      const month = (`0${date.getMonth() + 1}`).slice(-2);
      const day = (`0${date.getDate()}`).slice(-2);
      return `${year}-${month}-${day}`;
    },
    validateAndFormatDate(dateString) {
      const date = new Date(dateString);
      const dataInicio = new Date(this.dataInicio);
      const dataFim = new Date(this.dataFim);

      if (date < dataInicio) {
        return this.formatDate(this.dataInicio);
      } else if (date > dataFim) {
        this.$toast.error('Data de Início não pode ser maior que a Data de Fim.');
        return '';
      } else {
        return this.formatDate(date);
      }
    },
  },
  computed: {
    formattedmatmed() {
      // console.log(this.matmed)
      return this.matmed.map(matmed => {
        return {
          ...matmed,
          displayText: matmed.nomecomercial ? `${matmed.nome_substancia} - "${matmed.nomecomercial}"` : (`${matmed.nome_substancia}`)
        };
      });
    },
  },
  components: { ConfirmButton },
  watch: {
    'newMatMed.preco': function () {
      this.updateTotal();
    },
    'newMatMed.data_inicio_matmed': function (newVal) {
      const dataInicio = new Date(this.dataInicio);
      const dataIniciomatmed = new Date(newVal);

      if (dataIniciomatmed < dataInicio) {
        this.newMatMed.data_inicio_matmed = this.formatDate(this.dataInicio);
      } else {
        this.updateQuantityAndTotal();
      }
    },
    'newMatMed.data_fim_matmed': 'updateQuantityAndTotal',
    'newMatMed.quantidade_matmed': 'updateTotal',
    'newMatMed.matmed': function (newVal) {
      if (newVal) {
        const selectedMatMed = this.matmed.find(taxa => taxa.id === newVal);
        if (selectedMatMed) {
          this.newMatMed.preco = selectedMatMed.preco;
          this.updateQuantityAndTotal();
        }
      }
    },
    dataFim(newVal) {
      if (newVal) {
        this.newMatMed.data_fim_matmed = newVal;
        this.updateQuantityAndTotal();
      }
    },
    'newMatMed.aditivo_matmed': function (newVal) {
      if (newVal) {
        this.newMatMed.nr_aditivo = this.maxNrAditivo || 1;
        this.newMatMed.data_aditivo = this.maxDataAditivo || new Date().toISOString();
        this.newMatMed.data_inicio_matmed = this.maxDataAditivo || new Date().toISOString()
      } else {
        this.newMatMed.nr_aditivo = null;
        this.newMatMed.data_aditivo = null;
        this.newMatMeds.data_inicio_matmed = this.formatDate(new Date().toISOString().substr(0, 10))
      }
    },
    'newMatMed.nr_aditivo': function (newVal) {
      if (newVal != null) {
        if (newVal == this.maxNrAditivo) {
          this.newMatMed.data_aditivo = this.maxDataAditivo || this.formatDate(new Date().toISOString().substr(0, 10));
          this.newMatMed.data_inicio_matmed = this.maxDataAditivo || this.formatDate(new Date().toISOString().substr(0, 10));
        } else {
          const data = this.dataInicio < this.formatDate(new Date().toISOString().substr(0, 10)) ? this.formatDate(new Date().toISOString().substr(0, 10)) : this.dataInicio
          this.newMatMed.data_aditivo = data;
          this.newMatMed.data_inicio_matmed = this.formatDate(new Date().toISOString().substr(0, 10))
        }
      }
    },
    maxNrAditivo(newVal) {
      if (newVal !== null && newVal !== undefined && newVal !== '') {
        this.newMatMed.nr_aditivo = newVal;
        this.newMatMed.data_aditivo = this.maxDataAditivo || this.formatDate(new Date().toISOString());
      }
    },
    maxDataAditivo(newVal) {
      if (newVal !== null && newVal !== undefined && newVal !== '') {
        this.newMatMed.data_aditivo = newVal;
      }
    }
  },
  mounted() {
    this.getMatmed()
      .then(() => this.getAditivo())
      .then(() => this.getPacote())
      .finally(() => {
        const hoje = new Date();
        const dataInicio = new Date(this.dataInicio);
        const dataFim = new Date(this.dataFim)

        if (hoje < dataInicio) {
          this.newMatMed.data_inicio_matmed = this.formatDate(this.dataInicio);
          this.newMatMed.data_fim_matmed = this.formatDate(this.dataInicio);
        } else if (hoje > dataFim) {
          this.newMatMed.data_inicio_matmed = this.formatDate(this.dataFim);
          this.newMatMed.data_fim_matmed = this.formatDate(this.dataFim);
        } else {
          this.newMatMed.data_inicio_matmed = this.formatDateHoje(hoje);
          this.newMatMed.data_fim_matmed = this.formatDateHoje(hoje);
        }
        this.isLoading = false; // Definir isLoading como false após todas as requisições
    });
  }
}
</script>
